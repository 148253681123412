import React from 'react';

import { ErrorText } from '../ErrorText';
import { HelpText } from '../HelpText';
import { Label } from '../Label';

const Dropdown = ({
  children,
  value,
  onChangeFunction,
  onChangeParameters,
  onBlur,
  label,
  id,
  required,
  helpText,
  errorText,
  disabled,
  multiple,
  name,
}) => {
  const dropdownStyle = multiple
    ? 'form-multiselect sp-input sp-select-field'
    : 'form-select sp-input sp-select-field';

  return (
    <div>
      {label ? <Label label={label} id={id} /> : null}
      <div className="sp-input-field-div-wrapper flex">
        <select
          id={id}
          value={value}
          name={name}
          onChange={(e) => {
            e.preventDefault();
            if (multiple === true) {
              onChangeFunction(...onChangeParameters, e.target.selectedOptions);
            } else {
              onChangeFunction(...onChangeParameters, e.target.value);
            }
          }}
          onBlur={onBlur}
          className={dropdownStyle}
          required={required}
          disabled={disabled}
          multiple={multiple}
        >
          {children}
        </select>
      </div>
      {errorText ? (
        <ErrorText text={errorText} />
      ) : (
        <HelpText>{helpText}</HelpText>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  id: null,
  value: '',
  label: '',
  required: false,
  helpText: null,
  errorText: null,
  disabled: false,
  multiple: false,
  name: '',
  onBlur: null,
};

export default Dropdown;
