import React from 'react';

import Icon from '../Icon';

import { usePagination, dots } from './usePagination';

const Pagination = ({
  totalCount,
  siblingCount = 1,
  listItems,
  setLimit,
  setOffset,
  offset,
  limit,
  options = [10, 30],
  allOption = true,
}: {
  totalCount: number;
  siblingCount?: number;
  listItems: unknown[];
  setLimit: (arg0: number) => void;
  setOffset: (arg0: number) => void;
  offset: number;
  limit: number;
  options?: number[];
  allOption?: boolean;
}) => {
  const currentPage = offset / limit + 1;
  const onPageChange = (currentPage: number) => {
    const firstItemIndex = (currentPage - 1) * limit;
    setOffset(firstItemIndex);
  };

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    limit,
  });

  if (currentPage === 0 || (paginationRange?.length ?? 0) < 1) {
    return null;
  }

  const nextPage = () => {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  };

  const selectedPage = `selected`;
  const lastPage = paginationRange?.[paginationRange.length - 1];
  const disabledBtn = `disabled`;

  return (
    <div className="flex flex-col items-center justify-between md:flex-row">
      <div className="my-1 text-sm">
        <p>
          Showing <span className="font-semibold"> {listItems.length}</span> of{' '}
          {totalCount} results
        </p>
      </div>
      <ul className="sp-pagination-btn-group" aria-label="Pagination">
        <li
          className={`sp-pagination-btn-item ${
            currentPage === 1 ? disabledBtn : ''
          }`}
          onClick={prevPage}
          role="button"
        >
          <Icon name="FiArrowLeft" />
          <span className="sr-only sm:not-sr-only sm:ml-2">Previous</span>
        </li>

        {paginationRange?.map((pageNum, i) => {
          if (pageNum === dots) {
            return (
              <li
                key={`...${i}`}
                className="border-sp-neutral-300 text-sp-neutral-800 relative inline-flex items-center border bg-white px-4 py-2 text-sm font-medium "
              >
                ...
              </li>
            );
          }

          return (
            <li
              key={`page-${pageNum}-${i}`}
              className={`sp-pagination-btn-item ${
                currentPage === pageNum && selectedPage
              }`}
              onClick={() => onPageChange(pageNum)}
              role="button"
            >
              {pageNum}
            </li>
          );
        })}

        <li
          className={`sp-pagination-btn-item ${
            currentPage === lastPage ? disabledBtn : ''
          } `}
          onClick={nextPage}
          role="button"
        >
          <span className="sr-only sm:not-sr-only sm:mr-2">Next</span>
          <Icon name="FiArrowRight" />
        </li>
      </ul>

      <div className="w-28">
        <select
          id="pagination-change-limit"
          placeholder="Select an option"
          value={limit}
          onChange={(e) => {
            const valueAsInt = parseInt(e.target.value, 10);
            setLimit(valueAsInt);
          }}
          className="form-select sp-input sp-select-field my-1"
        >
          {options.map((option) => (
            <option key={option} value={option.toString()}>
              Show {option}
            </option>
          ))}
          {allOption && <option value={totalCount}>Show all</option>}
        </select>
      </div>
    </div>
  );
};

export default Pagination;
