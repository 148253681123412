import React from 'react';

const ToggleButton = ({
  checked,
  onClickFunction,
  onClickParameters,
  disabled = false,
}) => {
  const backgroundColor = checked
    ? 'bg-sp-primary-600 hover:bg-sp-primary'
    : 'bg-sp-neutral-100 hover:bg-sp-neutral-200';

  const sliderTranslate = checked ? 'translate-x-4' : 'translate-x-0';

  return (
    <button
      className={`${backgroundColor} focus:ring-sp-primary-100 relative flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-xl border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring disabled:cursor-not-allowed disabled:opacity-50`}
      type="button"
      role="checkbox"
      onClick={(e) => {
        onClickFunction(...onClickParameters);
      }}
      disabled={disabled}
    >
      <span
        aria-hidden="true"
        className={`${sliderTranslate} h-4 w-4 transform rounded-full bg-white shadow-sm transition duration-200 ease-in-out focus:outline-none`}
      ></span>
    </button>
  );
};

export default ToggleButton;
