import { IconType } from 'react-icons';
import { AiOutlineEnter, AiOutlineGoogle } from 'react-icons/ai';
import { BsCartCheck, BsGlobe, BsCheckCircle } from 'react-icons/bs';
import { CgArrowLongRight } from 'react-icons/cg';
import {
  FaRegQuestionCircle,
  FaRegDotCircle,
  FaCaretRight,
  FaCaretDown,
  FaStar,
} from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import {
  FiAlertCircle,
  FiInfo,
  FiPlus,
  FiCheck,
  FiTrash2,
  FiTv,
  FiUploadCloud,
  FiArrowUpCircle,
  FiArrowDownCircle,
  FiStar,
  FiMoreVertical,
  FiSearch,
  FiBookmark,
  FiAlertTriangle,
  FiCopy,
  FiHelpCircle,
  FiArrowUp,
  FiMove,
  FiEdit2,
  FiTruck,
  FiSave,
  FiTrendingUp,
  FiBarChart,
  FiDownload,
  FiFlag,
  FiPercent,
  FiPaperclip,
  FiExternalLink,
  FiChevronUp,
  FiChevronDown,
  FiFileText,
  FiFilter,
  FiCheckCircle,
  FiXCircle,
  FiUsers,
  FiChevronsDown,
  FiChevronsUp,
  FiChevronRight,
  FiChevronLeft,
  FiChevronsRight,
  FiChevronsLeft,
  FiArchive,
  FiRefreshCw,
  FiArrowDown,
  FiX,
  FiLifeBuoy,
  FiSettings,
  FiLogOut,
  FiUser,
  FiArrowLeft,
  FiArrowRight,
  FiLock,
  FiSmartphone,
  FiMessageSquare,
  FiSquare,
  FiMinusSquare,
  FiPlusSquare,
  FiLink,
  FiCalendar,
  FiAward,
} from 'react-icons/fi';
import { HiOutlineTable, HiOutlineLightBulb, HiUsers } from 'react-icons/hi';
import {
  MdDragIndicator,
  MdPublish,
  MdUnpublished,
  MdNewReleases,
  MdOutlineRestore,
} from 'react-icons/md';
import { RiSurveyLine } from 'react-icons/ri';
import { TbPageBreak } from 'react-icons/tb';

export interface IconsType {
  caretRight: IconType;
  caretDown: IconType;
  dangerFlag: IconType;
  errorCircleExclamation: IconType;
  infoCircleLetterI: IconType;
  plus: IconType;
  successTick: IconType;
  trashBin: IconType;
  warningTriangleExclamation: IconType;
  bookmark: IconType;
  copy: IconType;
  trashCan: IconType;
  FiArrowUp: IconType;
  FiArrowDown: IconType;
  circleQuestionMark: IconType;
  disk: IconType;
  move: IconType;
  pencil: IconType;
  truck: IconType;
  tv: IconType;
  trendingUp: IconType;
  barChart: IconType;
  newRelease: IconType;
  users: IconType;
  endState: IconType;
  filter: IconType;
  percent: IconType;
  exclamationTriangle: IconType;
  search: IconType;
  tick: IconType;
  closeCross: IconType;
  doubleArrowDown: IconType;
  doubleArrowUp: IconType;
  refresh: IconType;
  crossInCircle: IconType;
  arrowForward: IconType;
  arrowBackward: IconType;
  storageBox: IconType;
  star: IconType;
  FaStar: IconType;
  paperClip: IconType;
  clockHistory: IconType;
  pageBreak: IconType;
  surveyList: IconType;
  table: IconType;
  documentText: IconType;
  chevronDown: IconType;
  chevronUp: IconType;
  externalLink: IconType;
  dragDrop: IconType;
  download: IconType;
  publish: IconType;
  unpublish: IconType;
  alertCircle: IconType;
  upload: IconType;
  moreVertical: IconType;
  arrowUpCircle: IconType;
  arrowDownCircle: IconType;
  calendar: IconType;
  award: IconType;
  FiLifeBuoy: IconType;
  FiSettings: IconType;
  FiLogOut: IconType;
  FiUser: IconType;
  FiArrowLeft: IconType;
  FiArrowRight: IconType;
  AiOutlineEnter: IconType;
  FiChevronsUp: IconType;
  FiChevronsDown: IconType;
  FiChevronsLeft: IconType;
  FiChevronsRight: IconType;
  FiLock: IconType;
  FiMessageSquare: IconType;
  FiSquare: IconType;
  FiMinusSquare: IconType;
  FiPlusSquare: IconType;
  FiSmartphone: IconType;
  FiLink: IconType;
  BsCartCheck: IconType;
  BsGlobe: IconType;
  FcGoogle: IconType;
  CgArrowLongRight: IconType;
  AiOutlineGoogle: IconType;
  bulb: IconType;
  question: IconType;
  HiUsers: IconType;
  BsCheckCircle: IconType;
  FaRegDotCircle: IconType;
}

export const IconsList: IconsType = {
  caretRight: FaCaretRight,
  caretDown: FaCaretDown,
  dangerFlag: FiFlag,
  errorCircleExclamation: FiAlertCircle,
  infoCircleLetterI: FiInfo,
  plus: FiPlus,
  successTick: FiCheckCircle,
  trashBin: FiTrash2,
  warningTriangleExclamation: FiAlertTriangle,
  bookmark: FiBookmark,
  copy: FiCopy,
  trashCan: FiTrash2,
  FiArrowUp: FiArrowUp,
  FiArrowDown: FiArrowDown,
  circleQuestionMark: FiHelpCircle,
  disk: FiSave,
  move: FiMove,
  pencil: FiEdit2,
  truck: FiTruck,
  tv: FiTv,
  trendingUp: FiTrendingUp,
  barChart: FiBarChart,
  newRelease: MdNewReleases,
  users: FiUsers,
  endState: FiFlag,
  filter: FiFilter,
  percent: FiPercent,
  exclamationTriangle: FiAlertTriangle,
  search: FiSearch,
  tick: FiCheck,
  closeCross: FiX,
  doubleArrowDown: FiChevronsDown,
  doubleArrowUp: FiChevronsUp,
  refresh: FiRefreshCw,
  crossInCircle: FiXCircle,
  arrowForward: FiChevronRight,
  arrowBackward: FiChevronLeft,
  storageBox: FiArchive,
  star: FiStar,
  FaStar: FaStar,
  paperClip: FiPaperclip,
  clockHistory: MdOutlineRestore,
  pageBreak: TbPageBreak,
  surveyList: RiSurveyLine,
  table: HiOutlineTable,
  bulb: HiOutlineLightBulb,
  question: FaRegQuestionCircle,
  documentText: FiFileText,
  chevronDown: FiChevronDown,
  chevronUp: FiChevronUp,
  externalLink: FiExternalLink,
  dragDrop: MdDragIndicator,
  download: FiDownload,
  publish: MdPublish,
  unpublish: MdUnpublished,
  alertCircle: FiAlertCircle,
  upload: FiUploadCloud,
  moreVertical: FiMoreVertical,
  arrowUpCircle: FiArrowUpCircle,
  arrowDownCircle: FiArrowDownCircle,
  calendar: FiCalendar,
  award: FiAward,
  FiLifeBuoy: FiLifeBuoy,
  FiSettings: FiSettings,
  FiLogOut: FiLogOut,
  FiUser: FiUser,
  FiArrowLeft: FiArrowLeft,
  FiArrowRight: FiArrowRight,
  AiOutlineEnter: AiOutlineEnter,
  FiLock: FiLock,
  FiSmartphone: FiSmartphone,
  FiSquare: FiSquare,
  FiMessageSquare: FiMessageSquare,
  FiMinusSquare: FiMinusSquare,
  FiPlusSquare: FiPlusSquare,
  FiChevronsRight: FiChevronsRight,
  FiChevronsLeft: FiChevronsLeft,
  FiChevronsUp: FiChevronsUp,
  FiChevronsDown: FiChevronsDown,
  FiLink: FiLink,
  BsCartCheck: BsCartCheck,
  BsGlobe: BsGlobe,
  FcGoogle: FcGoogle,
  CgArrowLongRight: CgArrowLongRight,
  AiOutlineGoogle: AiOutlineGoogle,
  HiUsers: HiUsers,
  BsCheckCircle: BsCheckCircle,
  FaRegDotCircle: FaRegDotCircle,
};
