import React from 'react';

import { IconsType } from '../Icon/IconsList';

export type IconNamesType = keyof IconsType;

interface TabProps {
  label: string;
  tabName: string;
  badge?: number | undefined;
  iconName?: IconNamesType;
  children: React.ReactNode;
  trackClick?: () => void;
}

const Tab = ({
  label,
  tabName,
  badge,
  children,
  iconName,
  trackClick,
  ...otherProps
}: TabProps): JSX.Element => {
  return (
    <div aria-labelledby={`${label} content`} {...otherProps}>
      {children}
    </div>
  );
};

Tab.defaultProps = {
  badge: null,
  trackClick: null,
};

export default Tab;
